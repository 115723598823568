/**
 * Imports external CSS file for colors and text styles.
 */
@import url('./colorAndText.css');


/**
 * Defines root variables for box padding and border radius.
 */
:root{
    --box-padding: 50px; 
    --box-border-radius: 10px; 
}

/**
 * Styles for the page container
 */
.page-container{
    padding-top: var(--header-height);
    max-width: auto; 
    margin: auto;
    text-align: center; 
    flex-grow: 1; 
    overflow: auto; 
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}




/**
 * Applies to all major sections for consistent spacing and alignment 
 */
.section-container 
{
    margin: 0 auto;
    max-width: 800px; 
    margin-bottom: 50px; 
    padding: 20px; 
    text-align: left; 
    font-family: var( --body-font); 
    font-size: var(--normal-font-size);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap; 

}


/** 
 * Container for the content on the page
 */
.content-container {
    padding-top: var(--header-height); 
    overflow-y: auto;
}


/*============= BOX CONTAINERS ===================*/
/* Used for login, sinup and more */

/* container for light and dark box */
.box-container {
    display: flex;                                   
    flex-wrap: wrap;                                 
    justify-content: center;                          
    align-items: stretch;                           
    max-width: 50%;                                   
    margin: 0 auto;                                   
}

/* container for popup box */
.popup-box-container {
    display: flex;                                   
    flex-wrap: wrap;                                 
    justify-content: center;                          
    align-items: center;                              
    height: 100vh;                                   
    max-width: 70%;                                   
    margin: 0 auto;                                    
}



/* information about each background box */
.box {
    padding: var(--box-padding);                
    display: flex;                   
    flex-direction: column;          
    justify-content: center;          
    align-items: center;              
    transition: transform 0.5s;      
    border-radius: var(--box-border-radius);
    width: 100%;   
    flex: 1 1 auto;  
}

/* Light purple color */
.box.light {
    width: 60%;  
    background-color: var(--box-color-light);  
    height: auto;
    min-height: 50%;
    
}

/* Dark purple color */
.box.dark {
    width: 40%;  
    background-color: var(--box-color-dark);  
    height: auto;
    min-height: 50%;
    
}

/* Message box for displaing messages on the admin page*/
.box.message {
    width: 100%;  
    background-color: var(--box-color-dark);  
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
}

/*============= DROPDOWN ===================*/
  /* Custom styles for the select dropdown */
.select-dropdown {
    width: auto; 
    padding: 0.375rem 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 4px; 
    background-color: var(--button-bg-colo-light); 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);  
    cursor: pointer;  
}

.select-dropdown:hover {
    background-color: #e9ecef;  
}

/* Style the options */
.select-dropdown option {
    padding: 8px 12px; 
}


/*============= ADMIN PAGE ===================*/
/**
 * page content for the admin page 
 */ 
 .Admin-page-content {
    flex-grow: 1;
    overflow: hidden;
    padding: 20px;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;  
    padding: 20px;
    justify-content: center;  
    max-width: 1200px;  
    margin: 0 auto;  
}

.dashboard-grid > div {
    min-width: 250px;  
}


.messages-list-container {
    max-height: 500px; 
    overflow-y: auto; 
    padding: 10px;
    margin-bottom: 20px;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  
    gap: 20px;  
    padding: 20px;
}

@media (max-width: 1200px) {
    .dashboard-grid {
        grid-template-columns: repeat(2, 1fr);  
    }
}

@media (max-width: 768px) {
    .dashboard-grid {
        grid-template-columns: 1fr;  
    }
}


/* ============= IMAGE CONTAINERS (used in calculators)============= */
.calculator-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
}

/* DENNE BRUKES FOR Å MINSKE BILDET RIKTIG*/
.calculator-image-container img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}