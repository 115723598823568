/* Background box for the calculator container */
.custom-box {
    width: 100%;
    height: auto;
    background-color: var(--box-color-dark);
    border-radius: 10px; 
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: var(--box-shadow);
}

/* Container for the actual calculator */
.calculator-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: var(--box-color-light);
    border-radius: 5px;
    box-shadow: var(--box-shadow);
}

/* Get space around the printed result */
.result {
    margin-top: 1.5rem;
}