
/* General container for counters with rounded edges, center alignment, and specific size */
.counter-container,
.add-counter-box {
  border-radius: 16px;
  text-align: center;
  padding: 20px; 
  min-height: 150px; 
  width: 150px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  flex: 0 0 auto;
}

/* Styling for add-counter button, clickable with dashed border */
.add-counter-box {
  cursor: pointer; 
  border: 2px dashed var(--link-color);
  background-color: var(--box-color-light);
  font-size: var(--normal-font-size);
  color: var(--box-text-colour);
}

/* Fullscreen overlay for popup */
.pop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex; 
  align-items: center; 
  justify-content: center;
}

/* Container for popup content with dark background and centered text */
.pop-content {
  background-color: var(--box-color-dark);
  padding: 20px;
  border-radius: 16px; 
  text-align: center;
}

/* Styling for text inputs within pop-content, including padding and rounded borders */
.pop-content input[type="text"] {
  margin-bottom: 10px; 
  padding: 10px; 
  border-radius: 8px; 
}

/* Styling for the main counter container */
.counter-container {
  background-color: var(--box-color-dark); 
  overflow: auto;
}

/* Styling for text displaying counter information */
.counter-info {
  font-size: var(--h2-font-size); 
  color: var(--box-text-colour);
}

/* Flex container for counter control buttons (add, subtract) */
.counter-controls {
  display: flex; 
  justify-content: center; 
}

/* Button styling within counter controls */
.counter-controls button {
  padding: 5px 10px; 
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
  font-size: var(--normal-font-size); 
  background-color: var(--button-bg-colo-light); 
  color:  var(--button-text-color-dark); 
}

/* Hover effect for buttons in counter controls */
.counter-controls button:hover{
  background-color: var(--button-hover-light); 
  opacity: 0.8;
}

/* Container for multiple boxes, handling layout and spacing */
.boxes-container {
  display: flex;
  flex-direction: row; 
  align-items: start;
  gap: 20px;
  flex: 0 0 auto;
}

/* Editable text link style, underlined and interactive */
.edit-text {
  margin-top: 5px;
  margin-bottom: -5px;
  cursor: pointer; 
  color: var(--text-color); 
  text-decoration: underline; 
  font-size: var(--small-font-size);
  text-align: center; 
}

/* Hover effect for editable text, changing color */
.edit-text:hover{
  color: var(--link-hover-color);
}