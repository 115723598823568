/* ============= IMPORT FONTS ============= */

@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600&display=swap");
@import url("./colorAndText.css"); 



@media screen and (min-width: 1023px) {
    :root {
        --h2-font-size: 1.5rem;
        --normal-font-size: 1rem;
    }
}

/* ============= BASE ============= */

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
html{
    scroll-behavior: smooth;
}


body,
input,
button {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

body {
    background-color: var(--background-color);
    color: var(--text-color);
}

input,
button{
    border: none;
    outline: none;
}


/* ============= CLASSES ============= */


.container{
    max-width: 1120px;
    
    margin-inline: 1.25rem;

}

.main{
    position: relative;
    height: 100vh;

}



img{
    display: block;
    max-width: 100%;
    height: auto;
}


/* ============= HEADER ============= */

.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--second-light);
    box-shadow: 0 2px 16px var(--shadow-color);
    z-index: 100;
}

.nav{
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.nav-container{
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 160px;
    max-width: 3000px;
    position: relative;
    column-gap: 1rem;
}


.nav-items{
    display: flex;
    justify-content: center;
    align-content: center;
    column-gap: 1rem;
}

.nav-logo{
    color: var(--logo-color);
    font: var(--main-font);
    font-weight: var(--font-semi-bold);
    margin-right: auto;
    

}
.nav-logo-img{
    height: var(--header-height);
    width: auto;
    
    
}

.nav-login,
.nav-signup,
.nav-toggle,
.nav-close{
    font-size: var(--h3-font-size);
    color: var(--color-text-navbar);
    cursor: pointer;
    transition: color .4s;

}




.nav-list{
    display: flex;
    flex-direction:column;
    row-gap: 2.5rem;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    
}

.nav-link{
    color: var(--color-text-navbar);
    font-weight: var(--font-semi-bold);
    transition: color .4s;

}
.nav-close{
    position: absolute;
    top: 1.15rem;
    right: 1.5rem;

}

/*SHOW MENU ITEM*/
.show-menu{
    top: 0;
}
.show-login .login__form {
    transform: translateY(0);
  }



/*============= BREAKPOINTS ===================*/
@media screen and (min-width: 576px) {
    .signup,
    .login{
        padding-top: 10rem;
    }

    .signup-close,
    .login-close {
        width: max-content;
        top: 5rem;
        left: 0;
        right: 0;
        margin-inline: auto;
        font-size: var(--h1-font-size);
    }
    
    .login-form {
        max-width: 400px;
        margin-inline: auto;
    }
}
  
  /* For large devices */
@media screen and (min-width: 1023px) {
    .nav {
      height: calc(var(--header-height) + 2rem);
      column-gap: 3rem;
    }
    .nav-close, 
    .nav-toggle {
      display: none;
    }
    .nav-menu {
      margin-left: auto;
      
    }
    .nav-list {
      flex-direction: row;
      column-gap: 3rem;
    }
  
    .login-form {
      padding: 3rem 2rem 3.5rem;
    }
}
  
@media screen and (min-width: 1150px) {
    .container {
      margin-inline: auto;
    }
}

@media screen and (max-width: 1023px) {
    .nav__menu {
      position: fixed;
      top: -100%;
      left: 0;
      background-color: var(--body-color);
      box-shadow: 0 8px 16px hsla(230, 75%, 32%, .15);
      width: 100%;
      padding-block: 4.5rem 4rem;
      transition: top .4s;
    }
  }