/* Set image and information text to appear beside eachother */
.section-container .team-member, 
.section-container .feature-item  {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

/* Image style specifically for the abaot page */
.team-member-image, .feature-image {
    width: 150px;
    border-radius: 50%;
}


