/* The statistic box */
.statistic-box {
    background-color: #FFF;
    border: 2px solid #E1E1E1;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px;
    text-align: center;
    width: 10rem;
    margin-top: 20px;
    height: 14rem;
}

/* The lable of the box */
.label {
    color: #555;
    font-size: var(--normal-font-size);
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 1rem;
}

/* The value inside the box */
.value {
    color: #333;
    font-size: var(--h1-font-size);
    font-weight: bold;
    padding: 1rem;
    text-shadow: 1px 1px 1px #e3e3e3;
    text-overflow: ellipsis;
}

/* The icon at the bottom of the box */
.icon {
    max-width: 80%;
    height: auto;
    margin-bottom: 5px;
}