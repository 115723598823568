.UpLoad-backdrop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for the overlay */
    z-index: 1050; /* Ensure it is above all other content */
}

.UpLoad-content {
    padding: 20px;
    background: rgba(242, 228, 228, 1); /* Your specified pinkish color in rgba */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensures all children are centered */
}
