/*============= VARIABLE CSS ===================*/


:root{
    --header-height: 4.5rem;

    /* ============= COLOURS ============= */

    --background-color: #fff4f1;
    --dark-background-color: #f7f7f7;
    --second-background-color: #fce6df;

    /* ============= NAVBAR COLORS ============= */
    --shadow-color: #D6AE9A;
    --second-light: #F2E4E1;

    --logo-color: #F6964B;
    --color-text-navbar: #F6964B;

    --border-color: #ccc;
    --border-background-color: #f7f7f7; 


    /* ============= COLOURS FOR BOXES ============= */
    --box-text-colour: #000000;
    --box-color-light: #F2E4E1;
    --box-color-dark: #D6AE9A;
    --box-shadow: 0 2px 16px rgba(0,0,0,0.1);


    /* ============= COLOURS FOR INPUT-BOXES ============= */
    --color-inputbox: #f7f7f7;


    /* ============= COLOURS FOR BUTTONS ============= */
    --button-bg-colo-light: #ffffff;
    --button-hover-light: #F6964B;
    --button-text-color-dark: #000000;
    
    --button-bg-colo-dark: #474747;
    --button-hover-dark: #F6964B;
    --button-text-color-light: #ffffff;


    /* ============= LINK AND TEXT COLOURS ============= */
    --text-color: #000000;
    --link-color: #F6964B;
    --link-hover-color: #d06514;

    --error-message: #f21e08; /* Bright red color for errors */


/* ============= FONT and TYPE ============= */
    --body-font: "Syne", sans-serif;
    /*"Rigot-Regular" sans-serif;*/
    --main-font: "Rigot", sans-serif;

    /* ============= FONT SIZE ============= */
    --h1-font-size: 1.8em;
    --h2-font-size: 1.5em;
    --h3-font-size: 1.2em;
    --normal-font-size: 1.0em;
    --small-font-size: 0.8em;


    /* ============= Font weight ============= */
    --font-narrow: 300;
    --font-regular: 500;
    --font-medium: 600;
    --font-semi-bold: 800;
}


/* ============= GENERAL TEXT STYLES ============= */
.h1 {
    font-size: var(--h1-font-size);
    font-family: var(--main-font);
    color: var(--main-color);
    font-weight: var(--font-semi-bold);
    text-align: center;
    margin-bottom: 2.5rem;
}

.h2 {
    font-size: var(--h2-font-size);
    font-family: var(--main-font);
    color: var(--main-color);
    font-weight: var(--font-semi-bold);
    text-align: center;
    margin-bottom: 2.5rem;
}

.infoText{
    font-size: var(--normal-font-size);
    font-family: var(--body-font);
    color: var(--text-color);
    text-align: center;
    margin-bottom: 2.5rem;
    flex: 1;
}


/*  A smaller version of the info text. Used in e.g. reset password */
.infoText-small{
    font-size: var(--small-font-size);      
    text-align: center;
}


/* ============= GENERAL TEXT STYLES FOR ERROR MESSAGE, URL, ETC ============= */

/* Styling for error messages */
.errorMsg {
    color: var(--error-message);    
    margin-top: 2px;    
    margin-bottom: 2px;
    font-size: var(--small-font-size);    
    font-weight: var(--font-narrow);    
    display: flex;
}

/* Styling for URL */
ul{
    list-style: none;
}

/* For styling links */
a{
    text-decoration: none;              
    color: var(--link-color);
}

/* For styling links when hovered */
a:hover{
    color: var(--link-hover-color);
    border-bottom: 1px solid;
}

