/* Card.css */



.card-container {
    display: flex;
    justify-content: space-between;  
    align-items: center;  
    flex-wrap: wrap;  
    padding: 20px; 
}



:root {
    
    --curve: 40;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .card {
    width: calc(33.333% - 20px);
    flex: 0 0 300px;
    margin: 10px;
    position: relative;
    height: 50%;  
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
    box-shadow: 5px 5px 8px var(--shadow-color);
    color: var(----box-color-dark);
    margin-bottom: 20px;
    font-family: "Syne", sans-serif; 
  }
  
  /* How the images on the cards should be */ 
  .card-image {      
    width: 100%;
    height: auto;
  }
  
  .card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;      
    border-radius: calc(var(--curve) * 1px);    
    background-color: var(--color-inputbox);      
    transform: translateY(100%);
    transition: .2s ease-in-out;
  }
  
  .card:hover .card-overlay {
    transform: translateY(0);
  }
  
  .card-header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 1em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;    
    background-color: var(--color-inputbox);
    transform: translateY(-100%);
    transition: .1s ease-in-out;
  }
  
  .card:hover .card-header {
    transform: translateY(0);
  }
  
  .card-title {
    font-size: var(--normal-font-size);
    margin: 0 0 .1em;
    color: var(--main-color);
  }
  
  
  .card-status {
    font-size: var(--small-font-size);
    color: #D7BDCA;
  }
  
  .card-description {
    padding: 0 0.5em 0.5em;
    margin: 0;
    color: var(--text-color);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }    

  .card-content {
    padding: 16px;
  }


/* For the instagram card */
.card-link {
  display: block;
  text-decoration: none;  
  color: inherit;   
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);   
  transition: box-shadow 0.3s ease-in-out;  
  border-radius: 15px;  
  position: relative;   
  overflow: hidden;   
}

/* For the instagram card when hoverd */
.card-link:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);  
}

/* The message that apperar when a card is hoverd  */
.hover-message {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);   
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:  var(--h3-font-size);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  font-family: var(--main-font);
}

.card-link:hover .hover-message {
  opacity: 1;   
}
