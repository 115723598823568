/* =============  BACKGROUND ============= */

.light-background{
    background-color: var(--second-background-color);
    
}

/* ============= HOME SECTION ============= */

.home-section {
	position: relative;
	z-index: 1;
	background-position: top center;
	background-repeat: no-repeat;
	padding-bottom: 30px !important;
	padding-top: 10px !important;
    
}

.home-header {
	padding-top: 30px !important;
    padding: 9rem 0 1rem !important;
}

.home-content {
	padding: 8rem 0 8rem !important;
	text-align: left;

}

.heading {
	font-size: 3.4em !important;
	padding-left: 50px !important;
}


.heading-name {
	font-size: 4.5em !important;
	padding-left: 45px !important;
}

.main-name {
	color: var(--color-text-navbar);
}
.landing-img {
    align-items: right;
    padding: 9rem 0 1rem !important;

}


.wave {
	animation-name: wave-animation;  
	animation-duration: 2.1s; 
	animation-iteration-count: infinite; 
	transform-origin: 70% 70%;  
	display: inline-block;
}

@keyframes wave-animation {
	0% {
		transform: rotate(0deg);
	}
	10% {
		transform: rotate(14deg);
	} 
	20% {
		transform: rotate(-8deg);
	}
	30% {
		transform: rotate(14deg);
	}
	40% {
		transform: rotate(-4deg);
	}
	50% {
		transform: rotate(10deg);
	}
	60% {
		transform: rotate(0deg);
	}  
	100% {
		transform: rotate(0deg);
	}
}
/* ============= ABOUT US  ============= */


.About-us-section{
    background: linear-gradient( 45deg, #f3c7bb,#fff4f1 35%);
}

.home-about-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
  }
  
.home-about-description {
    
    padding-top: 100px !important;
    padding-bottom: 20px !important;
    text-align: center;
  }
.bold-text{
    color: var(--color-text-navbar);
  }
  
.home-about-body {
    padding-top: 50px;
    padding-bottom: 80px;
    font-size: 1.4em !important;
    text-align: center;
}


/* ============= INTRODUCTION  ============= */

.intro-header{
    padding-top: 20px !important;
    padding: 5rem 0 1rem !important;
}


.intro-content{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
    text-align: center;

}


/* ============= INSPIRATION  ============= */