
/* ========= BUTTONS  ========== */
/* Button specifications */
button {
    padding: 10px 15px; 
    border: none;  
    border-radius: 5px;  
    cursor: pointer; 
    margin: 0 auto;
    margin-top: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
}


/* ========= CLOSE BUTTONS (X)  ========== */
.close-button {
    float: center;
    border: none;
    background: none;
    font-size: var(--normal-font-size)
}

/* ========= SWITCHING BUTTONS  ========== */
.switch-container {
    display: inline-block;
    border-radius: 5px; 
    overflow: hidden;  
    width: auto;  
}

/* Define all switch-options */ 
.switch-option {
    display: inline-block;
    padding: 5px 10px;  
    cursor: pointer;  
}

/* Changes on the switch option that is active */ 
.switch-option.active {
    background-color: var(--button-hover-dark);; 
    color:   var(--button-text-color-light);  
}

/* Define how the switch option should look when it is inactive */
.switch-option.inactive {
    background-color:  var(--button-bg-colo-light);  
    color:   var(--button-text-color-dark); 
}
