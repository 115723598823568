@import url("./colorAndText.css"); 


.pdf-viewer {
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 20px;
}

.document-container {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: auto;  
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
    width: auto;  
    max-width: 95%;  
}

.navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.navigation button {
    padding: 5px 10px;
    background-color: var(--button-hover-light);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.navigation button:disabled {
    background-color: #ccc;
}

.navigation span {
    font-size: 16px;
}

.resume-section {
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    width: 100%;
}
.close-button {
    padding: 5px 10px;
    margin: 10px;
    background-color: var(#ffffff);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.close-button:hover, .full-view-button:hover {
    background-color: var(--button-hover-light);
}
