@import url('../../GlobalStyles/navbar.css');

/* Container for the profile page with flexible layout and central alignment */
.profile-page-container {
    display: flex;                         
    flex-wrap: wrap;                             
    justify-content: center;                     
    align-items: center;                       
    height: 100vh;                                  
    max-width: 70%;                                 
    margin: 0 auto;                                 
}

/* Styles for individual profile page sections with centered content */
.profile-page {
    padding: var(--box-padding);                
    display: flex;                 
    flex-direction: column;     
    justify-content: center;        
    align-items: center;         
    transition: transform 0.5s;    
    border-radius: var(--box-border-radius);
}

/* Light-themed profile box with specific width and background color */
.profile-page.light {
    width: 60%;  
    background-color: var(--box-color-light);  
    height: 50%;
}

/* Styling for profile information text */
.profile-info {
    font-size: var(--normal-font-size);  
    margin: 5px 0; 
}

/* Styling for profile options text, similar to profile-info */
.profile-options {
    font-size: var(--normal-font-size); 
}

/* Dark-themed profile box with column layout and space management */
.profile-page.dark {
    width: 40%; 
    background-color: var(--box-color-dark);  
    height: 50%;
    display: flex;  
    flex-direction: column;  
    justify-content: space-between;  
    align-items: center; 
}

/* Styling the first paragraph in any dark box to have top margin */
.box.dark p:first-child {
    margin-top: 20px;
}

/* Styling for profile images to be circular and fit the container */
.profile-image-container img {
    width: 70px; 
    height: 70px;  
    border-radius: 50%; 
    object-fit: cover;  
}

/* Styling for profile name text, centered and with specific margin and font size */
.profile-name {
    text-align: center;
    margin-top: 10px;  
    font-size: var(--normal-font-size);  
}