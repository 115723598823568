
/* ------------- CONTACT INFO  ------------- */

/* Image under contact details */
.contact-image {
    max-width: 90%; 
    height: auto;
    display: block; 
    padding: 0; 
    float: left;
    margin-top: -40px;
    margin-bottom: -80px;        
}


/* ------------- FAQ section ------------- */
/* The section for the questions */
.faq-section {
    width: 100%;  
    max-width: 1200px;  
    margin: 20px auto;  
    font-family: var(--body-font);
    padding: 20px; 
    box-sizing: border-box;  
    border: 1px solid #F6964B;
    border-radius: 8px;
}

/* The questions */
.faq-item {
    margin-bottom: 10px;
}

.faq-question {
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border:  var(--primary-color);
    outline: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: var(--h3-font-size);
}

/* Change the color of the question when open */
.faq-question.open {
    color:var(--link-color); 
}

.faq-answer {
    padding: 8px 20px;
    color: #333; 
    text-align: le;
    font-size: var(--normal-font-size);
    border-top: none; 
}

.icon {
    font-size: 24px; 
}

